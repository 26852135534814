const wrapTags = (text, endpoint) => {
  
  return `<a href="#/${ endpoint }">${ text }</a>`
  //return `<router-link to="{ name: '${ endpoint }' }">${ text }</router-link>`
}

export const QUESTIONNAIRE_SHOW = {
  questionnaire_notice: {
    fi: "Muutokset kyselyyn tallennetaan automaattisesti!",
    en: "Changes to questionnaire will be saved automatically!"
  },
  question_changed: {
    fi: "Alkuperäinen kysely on muuttunut, voit täyttää uuden kyselyn tai alustaa vastaukset uudella pohjalla klikkaamalla tästä",
    en: "Question's template has changed, you can fill new questionnaire or reset this question by clicking this"
  },
  upload_pdf: {
    fi: "Lisää pdf-tiedosto (vedä & pudota)",
    en: "Add pdf-file (drag & drop)"
  },
  preview_file: {
    fi: "Tiedostot (pdf)",
    en: "Files (pdf)"
  },
  dialog_d_headline: {
    fi: "Haluatko varmasti alustaa kohdan?",
    en: "Are you sure that you want to reset this?"
  },
  dialog_d_text: {
    fi: "Alustaminen poistaa kaikki täytetyt tiedot muuttuneesta kohdasta ja tiedot tulee täyttää uudelleen",
    en: "Resetting this question will remove all filled information from this questionnaire"
  },
  dialog_deletecard_headline: {
    fi: "Haluatko varmasti poistaa kortin?",
    en: "Are you sure you want to delete this card?"
  },
  dialog_deletecard_text: {
    fi: "Kortti ja sen täytetyt tiedot poistetaan lopullisesti tämän kyselyn vastauksista",
    en: "Card and all of its filled information will be deleted permanently from this questionnaire"
  },
  saved: {
    fi: "Tiedot tallennettu",
    en: "Answers saved"
  },
  close: {
    fi: "Sulje",
    en: "Close"
  },
  cancel: {
    fi: "Peruuta",
    en: "Cancel"
  },
  reset: {
    fi: "Alusta",
    en: "Reset"
  },
  delete: {
    fi: "Poista",
    en: "Delete"
  }
};

export const PORTAL_INDEX = {
  title: {
    fi: "Tarjoamme ratkaisuja",
    en: "We offer solutions"
  },//<a href=\"https://ardico.fi/portal#/themes\"></a>
  text: [{
      fi: `Arktiset kehittämisympäristöt -klusterin kautta ${wrapTags( "lappilaisten TKI-organisaatioiden asiantuntijapalvelut", 'themes')} ovat yritysten ja tutkimuksen saatavilla yhden luukun periaatteella.`,
      en: `Through the Arctic Development Environments cluster, ${wrapTags( "the services of Lapland’s RDI organizations", 'themes')} are available to companies and researchers on a one-stop-shop basis.`
    },
    {
      fi: `Klusteri tarjoaa yrityksille tukea ${wrapTags('tuotekehityksen elinkaaren eri vaiheissa', 'info')} sekä soveltuvan TKI-rahoituksen löytämisessä.`,
      en: `The cluster offers companies support at ${wrapTags('different stages of the product development life cycle', 'info')} and in finding suitable RDI funding.`
    },
    {
      fi: "Rahoittajille klusteri tarjoaa ennakointiin perustuvaa tietoa niistä teknologioista, joita alueelta puuttuu, ja selkeän palvelupisteen, johon rahoittajat voivat ohjata asiakkaitaan.",
      en: "For funders, the cluster provides foresight-based information about the technologies in the region and a service point to which funders can direct their clients."
    },
    {
      fi: "Klusterin avulla TKI-organisaatioiden on mahdollista palvella asiakkaitaan kattavammin ja ketterämmin.",
      en: "With the help of the cluster, RDI organizations to able to respond to customer needs in a more comprehensive and agile way."
    },
    {
      fi: wrapTags("Ota yhteyttä!", "contacts"),
      en: wrapTags("Contact us!", "contacts")
    }
  ],
  ideaTitle: {
    fi: "Idean jalostus tuotteeksi",
    en: "Processing the idea into a product"
  },
  ideaText: {
    fi: "Palvelu, tuote tai sovellus ideasta suojatuksi prototyypiksi",
    en: "A service, product, or application from an idea to a protected prototype"
  },
  servicesTitle: {
    fi: "Palvelut",
    en: "Services"
  },
  towardsMarketingTitle: {
    fi: "Kohti markkinoita",
    en: "Towards the market"
  },
  towardsMarketingText: {
    fi: "Palvelun, tuotteen tai sovelluksen demo testattu, jolla tuotanto ja markkinat selvillä",
    en: "Service, product, or application demo tested to help you understand production and markets"
  },
  commercializationTitle: {
    fi: "Kaupallistaminen",
    en: "Commercialization"
  },
  commercializationText: {
    fi: "Palvelu, tuote tai sovellus valmis markkinoille; tuotanto, asiakkaat ja jakelu kunnossa",
    en: "Service, product or application ready to market; production, customers and distribution ready"
  },
  button_1: {
    fi: "Katso kuinka teemme sen",
    en: "See how we do it"
  },
  button_2: {
    fi: "Katso yksittäiset palvelukuvaukset",
    en: "See individual service descriptions"
  },
  button_3: {
    fi: "Katso lista kehittämisympäristöistä",
    en: "See list of development environments"
  },
  developmentEnvironmentsTitle: {
    fi: "Kehittämisympäristöt",
    en: "Development environments"
  },
  developmentEnvironmentsText: [{
      fi: "Kehittämisympäristöt tuottavat asiantuntijapalveluita, joissa asiakkaat voivat testata tuotteitaan tai palveluita ja kehittää niitä edelleen. Kehittämisympäristöt ovat fyysisiä tai virtuaalisia oppimis- ja innovaatioympäristöjä, laboratorioita, tutkimustiloja, studioita, pajoja ja simulaatioympäristöjä.",
      en: "Development environments provide expert services where customers can test and further develop their products or services. Development environments are physical or virtual learning and innovation environments, laboratories, research facilities, studios, workshops, and simulation environments."
    },
    {
      fi: "Arktiset kehittämisympäristöt -klusteri kokoaa Lapissa erillään toimivat TKI-ympäristöt ja asiantuntijapalvelut yhtenäisiksi toimijoiksi, joka mahdollistaa elinkeinoelämän kehittymisen, yritysten investoinnit tuotekehitykseen sekä kansainvälistymiseen.",
      en: "Arctic development environments cluster brings Lapland juxtaposition of RDI environments and expert services aligned with players, which makes it possible development business development, business investments and internationalization."
    },
    {
      fi: "Arktiset kehittämisympäristöt klusteri koostuu Lapin ammattikorkeakoulun, Lapin yliopiston, Luonnonvarakeskuksen ja Geologian tutkimuskeskuksen tuottamista TKI-palveluista.",
      en: "Arctic development environments consists of a cluster of Lapland University of Applied Sciences, University of Lapland, Finland's Natural Resources and the Geological Survey of the production of RDI services."
    }
  ]
};

export const PORTAL_ENVIRONMENTS = {
  title: {
    fi: "Löydä Lapin parhaat tekijät!",
    en: "Find the best of Lapland!"
  },
  search: {
    fi: "Hae kehittämisympäristöistä",
    en: "Search from development environments"
  },
  card_title: {
    fi: "Kehittämisympäristöt",
    en: "Development environments"
  },
  titles: {
    fi: [
      "Kehittämisympäristö",
      "Toimialat",
      "TRL-arviointi",
      "Organisaatio",
      "Arviointi"
    ],
    en: [
      "Development environment",
      "Industries",
      "TRL-rating",
      "Organization",
      "Evaluation"
    ]
  },
  trl123: {
    fi: "Ideasta demoksi - suunnittelu ja prototyypin tekeminen",
    en: "From idea to demo - planning and prototyping"
  },
  trl456: {
    fi: "Demosta tuotantomalliksi - validoinnista ratkaisuihin ja tuotantomallin valmistukseen",
    en: "From demo to production model - from validation to solutions and model production"
  },
  trl789: {
    fi: "Kenttätestauksesta tuotantoon ja markkinointiin replikointi",
    en: "From field testing to production and to market replication"
  }
};

export const PORTAL_CLUSTER = {
  title: {
    fi: "Klusteri-info",
    en: "About cluster"
  }
}

export const PORTAL_INFO = {
  title: {
    fi: "Teknologiset valmiustasot Lapissa",
    en: "Technological Readiness Levels in Lapland"
  },
  trlbasic: {
    fi: "Teknologiset valmiustasot (TRL) ovat menetelmä arvioida Lapin kehittämisympäristöjen CTE:n (Critical Technology Elements) teknologista kypsyystasoa. Tasot määritellään teknologisen valmiuden arvioinnissa (Technology Readiness Assessment, TRA), jossa tutkitaan ohjelma- tai palvelukonsepteja, teknologisia vaatimuksia, ja demonstroituja teknologisia kykyjä. TRL pohjautuu 1-9 asteikolle, jossa 9 on kypsin teknologia. TRL:ien käyttäminen mahdollistaa teknologisen kypsyyden yhtenäisen ja johdonmukaisen keskustelun useiden erilaisten teknologioiden välillä. TRL-arviointi Lapin kehittämisympäristöissä perustuu Euroopan Unionin käyttämään asteikkoon.",
    en: "Technology readiness levels (TRL) are a method of estimating technology maturity of Critical Technology Elements (CTE) in Development Environments of Lapland. They are determined during a Technology Readiness Assessment (TRA) that examines program or service concepts, technology requirements, and demonstrated technology capabilities. TRL are based on a scale from 1 to 9 with 9 being the most mature technology. The use of TRLs enables consistent, uniform discussions of technical maturity across different types of technology. TRL estimating in Development environments of Lapland based in scale by the European Union."
  },
  infotitle: {
    fi: "Teknologiset valmiustasot",
    en: "Technological Readiness Levels"
  },
  info: [{
      id: 1,
      fi: "Perusperiaatteet havaittu",
      en: "Basic principles observed",
      color: "#72bf44"
    },
    {
      id: 2,
      fi: "Teknologiakonsepti on muotoiltu",
      en: "Technology concept formulated",
      color: "#72bf44"
    },
    {
      id: 3,
      fi: "Teknologiakonsepti on kokeellisesti todennettu",
      en: "Experimental proof of concept",
      color: "#72bf44"
    },
    {
      id: 4,
      fi: "Teknologian toimivuus on todennettu laboratorio-olosuhteissa",
      en: "Technology validated in lab",
      color: "#f7941e"
    },
    {
      id: 5,
      fi: "Teknologian toimivuus on todennettu asiaan kuuluvassa (todellisessa) ympäristössä",
      en: "Technology validated in relevant environment (industrially relevant environment in the case of key enabling technologies)",
      color: "#f7941e"
    },
    {
      id: 6,
      fi: "Teknologian toimivuus on demonstroitu asiaan kuuluvassa (todellisessa) ympäristössä",
      en: "Technology demonstrated in relevant environment (industrially relevant environment in the case of key enabling technologies)",
      color: "#f7941e"
    },
    {
      id: 7,
      fi: "(Järjestelmän) Prototyyppi on demonstroitu toimintaympäristössä",
      en: "System prototype demonstration in operational environment",
      color: "#b37ab4"
    },
    {
      id: 8,
      fi: "Järjestelmä on valmis ja toimiva",
      en: "System complete and qualified",
      color: "#b37ab4"
    },
    {
      id: 9,
      fi: "(Järjestelmän) lopullinen toimivuus on todennettu sen toimintaympäristössä (järjestelmä on valmis ja kilpailukykyinen teolliseen valmistukseen)",
      en: "Actual system proven in operational environment (competitive manufacturing in the case of key enabling technologies; or in space)",
      color: "#b37ab4"
    }
  ],
  irltitle: {
    fi: "Innovaatiokypsyystasot",
    en: "Innovation Readiness Levels"
  },
  irl: [{
      id: 1,
      fi: "Idea",
      en: "Idea",
      color: "#72bf44"
    },
    {
      id: 2,
      fi: "Keksintö",
      en: "Invention",
      color: "#72bf44"
    },
    {
      id: 3,
      fi: "Realiteettitarkastus",
      en: "Reality check",
      color: "#72bf44"
    },
    {
      id: 4,
      fi: "Alustava prototyyppi",
      en: "Preliminary prototype",
      color: "#f7941e"
    },
    {
      id: 5,
      fi: "Prototyyppi",
      en: "Prototype",
      color: "#f7941e"
    },
    {
      id: 6,
      fi: "Toimiva prototyyppi",
      en: "Functional prototype",
      color: "#f7941e"
    },
    {
      id: 7,
      fi: "Valmis tuote",
      en: "Final product",
      color: "#b37ab4"
    },
    {
      id: 8,
      fi: "Myyntituote",
      en: "Deliverable",
      color: "#b37ab4"
    },
    {
      id: 9,
      fi: "Markkinoilla oleva tuote",
      en: "Product placed on the market",
      color: "#b37ab4"
    }
  ],
  irlText: [{
      fi: 'Innovaatiokypsyystasot (IRL, Innovation Readiness Levels) kuvaavat prosessia, joka johtaa ideasta uuteen tuotteeseen markkinoilla. Asteikko perustuu teknologisiin valmiustasoihin.',
      en: 'Based on the TRL scale, Innovation Readiness Levels (IRL) describe the process from an idea to a new product on the market.'
    },
    {
      fi: 'Asteikon avulla yritys voi arvioida kehitettävien innovaatioiden kehityspolun vaiheen sekä ne tasot, joihin yrityksen toiminta sijoittuu. Innovaatioiden kehittäminen ei useinkaan ole suoraviivainen prosessi, vaan polku voi kulkea useampaan kertaan saman tasoportaan kautta. Yrityksen toiminta taas voi sijoittua vain tietyille tasoille, eikä pyrkimyksenä aina ole mennä siitä eteenpäin.',
      en: 'The scale allows companies to assess the development stage of their innovations, as well as the levels at which the company operates. Developing innovations is often not a straightforward process, and the path can pass multiple times through the same level. Companies can also choose to operate on certain levels only, not aiming to go beyond that.'
    },
    {
      fi: 'Innovaatiokypsyystasot jakautuvat kolmeen tuotantosykliin:',
      en: 'Innovation Readiness Levels are divided into three stages'
    }
  ],
  irlExplanation: [
    {
      fib: 'IRL 1–3: Idean jalostus tuotteeksi tai palveluksi.',
      enb: 'IRL 1–3: From idea to product.',
      fi: 'Palvelun, tuotteen tai sovelluksen kehittäminen ideasta suojatuksi prototyypiksi.',
      en: 'Developing a service/product/application from an idea to a prototype.'
    },
    {
      fib: 'IRL 4–6: Kohti markkinoita.',
      enb: 'IRL 4–6: Towards the market.',
      fi: 'Palvelu-, tuote- tai sovellusdemo on testattu. Tuotanto ja markkinat ovat selvillä.',
      en: 'Demo version tested. Production method identified and market research conducted.'
    },
    {
      fib: 'IRL 7–9: Kaupallistaminen',
      enb: 'IRL 7–9: Commercialisation',
      fi: 'Palvelu, tuote tai sovellus on valmis markkinoille. Tuotanto, asiakkaat ja jakelu ovat kunnossa',
      en: 'Ready for the market. Production and distribution methods selected, potential customers/clients identified. '
    }
  ],
  trlEvalTitle: {
    fi: "TRL-tason määrittäminen",
    en: "Determining the TRL"
  },
  trlEvalSubtitle: {
    fi: "Kysymyksessä on itsearviointi, jonka voi tehdä täyttämällä alla olevan lomakkeen.",
    en: "It is a question of self-evaluation, which is done by filling out the form below."
  },
  devTrlEvalTitle: {
    fi: "Kehittämisympäristöjen TRL-arviointi",
    en: "Development Environments' TRL-Evaluation"
  },
  devTrlEvalBasic: {
    fi: [
      "Kehittämisympäristön nimi ja organisaatio",
      "Osoite",
      "Vastuuhenkilö (nimi, titteli, yhteystiedot)"
    ],
    en: [
      "Name and organisation of the development environment",
      "Address",
      "Responsible person (name, title and contact)"
    ]
  },
  devTrlEvalTechTitle: {
    fi: "Teknologia",
    en: "Technology"
  },
  devTrlEvalTechContent: {
    fi: [
      "Temaattisten tieteenalojen kuvaus",
      "Käytössä olevat tilat ja muut palvelut",
      "Teknologiset ratkaisut, laitteistot ja laitteet (ohjelmistot, palvelut, testaus, toimintatavat jne.)",
      "Muut tukipalvelut (verkosto, alihankkijat, yhteistyökumppanit, muut kehittämisympäristöt)",
      "Laatujärjestelmät, standardit ja akkreditointi",
      "Referenssit (asiakkaat, palkinnot, menestystarinat)",
      "Asiakaspalaute (positiiviset asiat)"
    ],
    en: [
      "Description of the thematic field of sciences",
      "Available facilities and other services",
      "Technological solutions, equipment and tools (software, services, testing, methods etc.)",
      "Other support services (network, subcontractors, partners, other development environments)",
      "Quality systems, standards and accreditation",
      "References (customers, rewards, success stories)",
      "Customer feedback (positive things)"
    ]
  },
  devTrlEvalTechEmp: {
    fi: [
      "Henkilöstöresurssit",
      "Nimi, työ, koulutus/tutkinto, kokemus, erikoistaidot"
    ],
    en: [
      "Human resources and know-how",
      "Name, job, education/degree, experience, special skills"
    ]
  },
  devTrlEvalReadinessTitle: {
    fi: "Valmius",
    en: "Readiness"
  },
  devTrlEvalReadinessContent: {
    fi: [
      "Palvelun saavutettavuus (sisäinen organisaatio, aukioloajat, toiminta pyhien aikana)",
      "Palvelun saavutettavuus (sijainti, ulkoiset yhteydet etc.)",
      "Palvelut (mahdollisuudet täyttää asiakkaiden tarpeet)",
      "Vastausaika",
      "Toimituksen luotettavuus (aikataulut, riskinhallinta, tarkastuskäytännöt)",
      "Tietämys kehittämisohjelmista ja rahoitusjärjestelmistä"
    ],
    en: [
      "Availability of the service (internal organisation, opening hours, activities during holidays)",
      "Accessibility of the service (location, remote connections, customer use, the mobility of experts etc.)",
      "Services (chances to meet customer needs)",
      "Response time",
      "Reliability of delivery (schedules, risk management, inspection practices)",
      "Knowledge of development programs and financial systems"
    ]
  },
  devTrlEvalLevelTitle: {
    fi: "Taso",
    en: "Level"
  },
  devTrlEvalLevelContent: {
    fi: [
      "Päätelmä (lopputuloksen kuvaus, rajapinta ja sidos tapaukseen)",
      "Arviointi skaala 1-9 (yleis ja tapauskohtainen arvio)",
      "Yleinen TRL arvio: Laboratorio toimii TRL välillä x - x",
      "Tapauskohtainen TRL arvio: x"
    ],
    en: [
      "Conclusion (description of the outcome, interface and linkage to the case)",
      "Evaluation scale 1-9 (general and case specific estimate)",
      "General TRL estimate: Laboratory is working in TRL scale x - x",
      "Case specific TRL estimate: x"
    ]
  },
  trlSummary: {
    fi: [
      "Lopputuloksena on mahdollista saada kaksi erityyppistä TRL arviota - Yleinen TRL ja tapauskohtainen TRL",
      'Yleinen TRL arvio tarkoittaa kykyä millä kehittämisympäristöt "normaalisti" toimivat - se on jotakin välillä x...x',
      "Tapauskohtainen TRL arvio tarkoittaa tasoa jolla kehittämisympäristö on kykenevä toimimaan tietyssä tapauksessa (esimerkiksi H2020) - jos taso ei ole riittävä, tarkoittaa se tarvetta löytää sopiva yhteistyöpartneri"
    ],
    en: [
      "As a result there are possibilities to get two different types of TRLs - General TRL and Case specific TRL",
      'General TRL estimate means capability where environments "normally" works - it is something between x...x',
      "Case specific TRL estimate means the level where the development environment is capable in a particular case (for example H2020 call) - if the level is not enough for the needed case, it means there is a need to find co-operation suitable partner"
    ]
  }
};

export const PORTAL_REFERENCES = {
  title: {
    fi: "Lue alta tarinoita onnistumisistamme!",
    en: "Read our success stories below!"
  }
};

export const PORTAL_THEMES = {
  title: {
    fi: "Klikkaa mieleistäsi teemaa ja saat lisätietoja",
    en: "Click on a theme you like to learn more"
  },
  titles: {
    fi: [
      "Kehittämisympäristö",
      "Teemat",
      "Toimialat",
      "Organisaatio",
      "Lisätietoja"
    ],
    en: [
      "Development environment",
      "Themes",
      "Industries",
      "Organization",
      "Additional information"
    ]
  },
  reset: {
    fi: "tyhjennä",
    en: "reset"
  }
};

export const PORTAL_TOOLS = {
  title: {
    fi: "Työkalut kehittäjille ja yrittäjille",
    en: "Tools for developers and entrepreneurs"
  },
  tools: [{
      title: {
        fi: "TRL-työkalu",
        en: "TRL evaluation tool"
      },
      text: {
        fi: "Kehittämisympäristön teknologian valmiustason arvioimiseen",
        en: "To evaluate the technology readiness of the development environment"
      }
    },
    {
      title: {
        fi: "Palvelutarpeen arviointityökalu",
        en: "Service Needs Assessment Tool"
      },
      text: {
        fi: "Etsi käyttöösi sopiva ensisijainen palveluntarjoaja",
        en: "Find your preferred primary service provider"
      }
    },
    {
      title: {
        fi: "Rahoitusinstrumenttien valintatyökalu",
        en: "Financial Instruments Selection Tool"
      },
      text: {
        fi: "Tuotekehityskäyttöön parhaiten sopivan rahoitusinstrumentin valinta yrityksille",
        en: "Choosing the financial instrument best suited to companies for product development"
      }
    },
    {
      title: {
        fi: "Yrityksen digitaalisuuden tason arvioiminen",
        en: "Evaluate the level of enterprise digitalization"
      },
      text: {
        fi: "Työkalu tämän hetkisen digitaalisuuden tason arviointiin yrityksille",
        en: "A tool for companies to evaluate the current level of digitalisation"
      }
    },
    {
      title: {
        fi: "KV-kasvun rappuset",
        en: "Stairs to international growth"
      },
      text: {
        fi: "Työkalu kansainvälisyyden tason arviointiin yrityksille",
        en: "Tool for the assessment of the level of internationalization for companies"
      }
    }
  ]
};

export const PORTAL_GODIGITAL = {
  title: {
    fi: "Digitalisaatio",
    en: "Go-Digital"
  },
  heading: {
    fi: "Arktiset kehitysympäristöt -klusteri tarjoaa Lapin yrityksille yhden luukun yhteyspisteen jäsenorganisaatioidensa – alueen yliopistojen ja tutkimusorganisaatioiden – digitaalisen teknologian osaamiseen ja palveluihin.",
    en: "The Arctic Development Environments cluster provides businesses in Finnish Lapland a one-stop-shop access point to the digital technology expertise and services of its member organisations: universities and research organisations in the region."
  },
  heading2: {
    fi: "Klusteri tukee yrityksiä parantamaan prosessejaan, tuotteita ja palveluja digitalisaation avulla. Klusteria koordinoi Lapin ammattikorkeakoulu. Klusterin toiminta on voittoa tavoittelematonta, ja hinnoittelumalli perustuu todellisiin kustannuksiin. Suurin osa klusterikumppaniorganisaatioista on julkisesti rahoitettuja, ja tulot kohdistetaan takaisin yksiköille, joilla on toiminnasta aiheutuvia kustannuksia. Lisäksi klusteri tarjoaa yrityksille TKI-rahoitukseen liittyviä palveluita.",
    en: `
The cluster supports businesses by helping to improve their processes, products and services by means of digitalisation. 
The services can be accessed on the Ardico.fi service portal. </br></br>

    - ${wrapTags('Service themes', 'themes')}</br>
    - ${wrapTags('References', 'references')}</br>
    </br>
The cluster’s services span the whole ${wrapTags('TRL scale', 'info')}, with a novel test-before-invest service portfolio.`
  },
  link: {
    fi: `<a href="https://ardico.fi/digicap">Arvioi yrityksesi digitalisaation taso</a>`,
    en: `<a href="https://ardico.fi/digicap">Evaluate your company’s current level of digitalisation (in Finnish)</a>`
  },
  middle1: {
    fi: "Klusteri tarjoaa yrityksille tukea tuotekehityksen eri vaiheissa. Klusterin palveluihin kuuluvat: sopimustutkimus; test-before-invest; tuotekehitys; prototyyppien valmistus; liiketoiminnan kehitys; pääsy teknologia-alustoille ja innovaatiotiloihin; rahoituksen kartoitus ja haun tukeminen; ja koulutus.",
    en: "The cluster is coordinated by the Lapland University of Applied Sciences and its operations are not-for-profit, with a pricing model based on actual costs. Most of the cluster partner organisations are publicly funded and the revenue is allocated back to the units that have operational costs from providing the service. In addition, the cluster offers businesses services related to R&D funding (access to finance)."
  },
  middle2: {
    fi: "Klusteriin kuuluu 50 modernia infraa, kehittämisympäristöä ja laboratoriota (TEF) sekä yli 750 asiantuntijaa, jotka kattavat melkein koko Lapin. Klusteri kehittää myös alueiden välistä yhteistyötä klusterin kautta saatavien digitalisaatiopalvelujen kirjon laajentamiseksi.",
    en: "The cluster offers companies support at different stages of the product development cycle. The cluster's services include: contract research; test-before-invest; product development; prototyping; business development; access to technological platforms and innovation spaces; funding mapping and matching; and training."
  },
  middle3: {
    fi: `
    Klusterin digitalisaatiopalvelut ovat käytettävissä Ardico-palveluportaalin kautta. </br></br>
    - ${wrapTags('Palveluteemat', 'themes')}</br>
    - ${wrapTags('Referenssit', 'references')}</br>
    </br>
    Palvelut kattavat koko ${wrapTags('TRL-asteikon', 'info')} sekä test-before-invest-palveluportfolion`,
    en: "The cluster includes 50 modern infra, environments and laboratories (TEFs) and more than 750 experts and specialists, spanning almost the whole of Lapland. "
  },
  serviceModel: {
    fi: `<b>Klusterin palvelumalli:</b></br></br>

    <p class="ml-4">1. Yritys ottaa yhteyttä klusteriin.</p>
    
    <p class="ml-4">2. Klusteri tuottaa vaatimusmäärittelyn yrityksen tarpeiden perusteella.</p>
    
    <p class="ml-4">3. Yritys saa tarjouksen, jossa täsmennetään palvelun hinta ja aikataulu.</p>
    
    <p class="ml-4">4. Jos tarjous hyväksytään, yrityksen ja klusterin välillä tehdään palvelusopimus. Hinnoittelu perustuu palvelun todellisiin kustannuksiin.</p>
    
    <p class="ml-4">5. Klusteri kokoaa asiantuntijaryhmän ja koordinoi heidän työtään.</p>
    
    <p class="ml-4">6. Klusteri auttaa yritystä tunnistamaan ja hakemaan sopivaa TKI-rahoitusta.</p>
    
    <p class="ml-4">7. Klusteri toimittaa palvelun tuloksen yritykselle.</p>`,
    en: `<b>The cluster's service model:</b></br></br>

    <p class="ml-4">1. The company contacts the cluster.</p>
    
    <p class="ml-4">2. The cluster produces a requirement specification based on the company's need.</p>
    
    <p class="ml-4">3. The business receives a tender specifying the pricing and schedule of the service.</p>
    
    <p class="ml-4">4. If the tender is accepted, a service contract is drawn between the company and the cluster. The pricing is based on the actual costs of the service.</p>
    
    <p class="ml-4">5. A group of experts is selected and their work is coordinated by the cluster.</p>
    
    <p class="ml-4">6. The cluster assists the company with identifying and applying for suitable R&D funding.</p>
    
    <p class="ml-4">7. The cluster submits the deliverable to the company.</p>`
  },
  bottom: {
    fi:"Palvelumalli on kehitetty pilotoimalla pk-yritysten kanssa. Klusterin palveluja kehitetään edelleen asiakkaiden tarpeiden ja palautteen mukaan. Palvelun tavoitteena on tukea yritysten tuotteiden ja liiketoimintamallien kehittämistä laadukkaan tutkimuksen avulla. Lisäresursseja yritysten tukemiseen saadaan alueiden välisen ja kansainvälisen yhteistyön avulla.",
    en:"The service model has been developed through piloting with SMEs. The cluster’s services are further being developed according to customer needs and feedback. The ultimate goal is to support businesses in developing new products and business models and to launch them with the help of high-quality research. Further resources for supporting SMEs will be acquired through interregional and international cooperation. "
  }
};

export const INDUSTRY_40 = {
  title: {
    fi: "Teollisuus 4.0",
    en: "Industry 4.0",
  },
  titleQuestion: {
    fi: "Mikä teollisuus 4.0?",
    en: "What Industry 4.0?",
  },
  industry40Chapter1: {
    fi: "Teollisten vallankumousten sarjan aloitti höyrykone, seuraavaksi tuli massatuotanto ja kolmantena tietokone. Nyt olemme keskellä neljättä teollista vallankumousta, jonka teknologia keskittyy tekoälyyn, yhdistettävyyteen, "+
    "automatisaatioon, koneoppimiseen ja reaaliaikaiseen dataan. Tästä teollisesta vallankumouksesta käytetään käsitteitä Teollisuus 4.0 ja Industry 4.0. Industry 4.0, jota kutsutaan myös IIoT:ksi tai älykkääksi valmistukseksi, "+
    "yhdistää fyysisen tuotannon ja toiminnot älykkääseen digitaalitekniikkaan, koneoppimiseen ja massadataan luodakseen kokonaisvaltaisen ja paremmin yhdistetyn ekosysteemin yrityksille, jotka keskittyvät valmistukseen ja "+
    "toimitusketjun hallintaan. Yksi esimerkki on älytehdas, jossa on investoitu Teollisuus 4.0 -teknologiaan, -ratkaisuihin ja -lähestymistapoihin ja hyödynnetään niitä. Esineiden Internet (Internet of Things, IoT) -termiä "+
    "käytetään yleensä kuluttajatuotteista puhuttaessa. Teollinen Internet (Industrial Internet of Things, IIoT) -termiä vastaavasti käytetään teollisuuden laitteiden ja järjestelmien yhteydessä. Tietoa kerätään reaaliajassa "+
    "erilaisilla mittausmenetelmillä. Kerättyä tietoa analysoidaan ja hyödynnetään toimintojen automatisoinnissa, tehokkuuden lisäämisessä ja päätöksenteon apuna.",
    en: "The series of industrial revolutions was started by the steam engine, followed by mass production and third by the computer. Now we are in the middle of the fourth industrial revolution, with technology focused on "+
    "artificial intelligence, connectivity, automation, machine learning and real-time data. The terms Teollisuus 4.0 and Industry 4.0 are used for this industrial revolution. Industry 4.0, also called IIoT or intelligent "+
    "manufacturing, combines physical production and operations with intelligent digital technology, machine learning and big data to create a holistic and better connected ecosystem for companies focused on manufacturing and "+
    "supply chain management. One example is a smart factory, which has invested in and utilizes Industry 4.0 technology, solutions and approaches. The term Internet of Things (IoT) is usually used when talking about consumer "+
    "products. The term Industrial Internet (Industrial Internet of Things, IIoT) is correspondingly used in connection with industrial devices and systems. Information is collected in real time using various measurement methods. "+
    "Collected information is analyzed and utilized in the automation of operations, increasing efficiency and as a decision-making aid.",
  },
  smartFutureTitle : {
    fi: "Arctic Smart Future Technologies 2021-2023",
    en: "Arctic Smart Future Technologies 2021-2023",
  },
  smartFutureTitleChapter1: {
    fi: "Arctic Smart Future Technologies (ASFT) oli neljän lappilaisen oppilaitoksen (Lapin ammattikorkeakoulu (koordinoija), Lapin yliopisto, Ammattiopisto Lappia ja Lapin koulutuskeskus REDU) yhteinen hanke. Hankkeen tavoitteena"+
    " oli tiivistää ammatillisten oppilaitosten ja korkeakoulujen välistä poikkialaista yhteistyötä, jotta ne voivat entistä paremmin tukea lappilaisten yritysten kilpailukykyä. Lappilaiset yritykset voivat jo nykyisellään "+
    "hyödyntää laajasti oppilaitosten kehittämisympäristöjä. Tavoitteena on myös lisätä tietoisuutta olemassa olevista teknologioista sekä oppilaitosten kärkiosaamisesta. Yritykset ja oppilaitokset ottavat teknologioita haltuun "+
    "yhteisten ketterien kokeilujen eli pilottien avulla. Näin voidaan tunnistaa Teollisuus 4.0 -ajatteluun perustuvaa uutta osaamista, löytää uusia teknologioita ja niihin liittyviä sovellusmahdollisuuksia.",
    en: "Arctic Smart Future Technologies (ASFT) was a joint project of four Lapland educational institutions (Lapland University of Applied Sciences (coordinator), the University of Lapland, the Vocational College of Lapland "+
    "and the Lapland Education Center REDU). The aim of the project was to strengthen cross-national cooperation between vocational schools and universities, so that they can better support the competitiveness of Lapland companies. "+
    "Lapland companies can already make extensive use of educational institutions' development environments. The goal is also to increase awareness of existing technologies and the cutting-edge expertise of educational institutions. "+
    "Companies and educational institutions take over technologies with the help of joint agile experiments, i.e. pilots. In this way, new skills based on Industry 4.0 thinking can be identified, new technologies and related "+
    "application opportunities can be found.",
  },
  smartFutureTitleChapter2: {
    fi: "Hankkeessa tunnistettiin oppilaitosten kärkiosaaminen uusien teknologioiden saralla. Hankkeen tuloksena kehitettiin yhteistyötoimintamallia, jossa huomioitiin Lapin maakunnan alueen mikro- ja pk-yritysten erilaiset "+
    "lähtötasot ja tarpeet Teollisuus 4.0 liittyen. Parhaimmillaan toiminta parantaa maakunnan veto- ja pitovoimaa tarjoamalla selkeämmin uusiin teknologioihin liittyvät osaamiset ja resurssit yritysten kasvun tueksi. ASFT "+
    "-hankkeessa toteutettiin viisi ketterää yrityspilottia, joissa kussakin oli mukana vähintään kaksi oppilaitosta. Piloteissa ajatuksena oli se, että yritykset voivat niiden kautta testata oppilaitoksista löytyviä teknologioita "+
    "ja osaamista. Pilotit osoittivat konkreettisesti sen, miten monipuolisesti ammatti -ja korkeakoulujen välillä voidaan tehdä yhteistyötä. Piloteissa oppilaitosten osaamiset eivät kilpailleet keskenään vaan jokainen oppilaitos "+
    "toi oman osaamisensa työhön. Parhaimmillaan pilotit lisäsivät yritysten tietoisuutta oppilaitoksista löytyvästä osaamisesta, joka voisi tulevaisuudessa palvella yritysten tarpeita. ",
    en: "The project identified the leading expertise of educational institutions in the field of new technologies. As a result of the project, a cooperation operating model was developed, which took into account the different "+
    "starting levels and needs of micro and small businesses in the Lapland region in relation to Industry 4.0. At its best, the activity improves the attraction and holding power of the province by providing more clearly the "+
    "know-how and resources related to new technologies to support the growth of companies. In the ASFT project, five agile business pilots were implemented, each of which involved at least two educational institutions. In the "+
    "pilots, the idea was that companies can use them to test the technologies and know-how found in educational institutions. The pilots concretely demonstrated how versatile cooperation between professions and universities "+
    "can be. In the pilots, the competences of the educational institutions did not compete with each other, but each educational institution brought its own competence to the work. At best, the pilots increased companies' "+
    "awareness of the expertise found in educational institutions, which could serve companies' needs in the future."
  },
  smartFutureFootnote: {
    fi: "Voit lukea ASFT-hankkeesta lisää täältä: <a href=\"https://arcticsmartness.fi/arctic-smart-future-technologies-asft/\">https://arcticsmartness.fi/arctic-smart-future-technologies-asft/</a>",
    en: "You can read more about the ASFT project here: <a href=\"https://arcticsmartness.fi/arctic-smart-future-technologies-asft/\">https://arcticsmartness.fi/arctic-smart-future-technologies-asft/</a>",
  },
  resultsTitle : {
    fi: "Hankkeen tulokset",
    en: "Project results",
  },
  resultsDescription : {
    fi: "Hankkeen tulokset esitetään kahden visualisoidun koonnin kautta: Tiekartta kohti visiota 2028 sekä Yhteistyön toimintamalli. Molemmat visualisoinnit on esitetty koneistoina kuvaten jokaisen osa-alueen toiminnan tärkeyttä - jokainen koneen osa on tärkeä, jotta kokonaisuus toimii. ",
    en: "The results of the project are presented through two visualized compilations: Roadmap towards vision 2028 and Cooperation operating model. Both visualizations are presented as mechanisms, describing the importance of the operation of each sub-area - each part of the machine is important for the whole to function.",
  },
  roadmapTitle : {
    fi: "Tiekartta kohti visiota 2028",
    en: "Roadmap towards vision 2028",
  },
  visionsDescription1: {
    fi: "Visio rakentuu viiden tunnistetun polun kautta.",
    en: "The vision is built through five identified paths.",
  },
  visions: {
    fi: [
      "Kiertotalous ja vihreä siirtymä",
      "Yhteistyön kasvattaminen kokonaisvaltaisesti",
      "Alueellisuus",
      "Kehittyvä koulutus",
      "Teknologia ja innovaatiot"
    ],
    en: [
      "Circular economy and green transition",
      "Increasing cooperation comprehensively",
      "Regionality",
      "Developing education",
      "Technology and innovations"
    ]
  },
  visionsDescription2: {
    fi: "Näille kaikille poluille on hankkeen aikana tunnistettu nykytila, kolme tärkeää tavoitetilaa sekä visio.",
    en: "For all these paths, the current state, three important target states and a vision have been identified during the project.",
  },
  visionsDescription3: {
    fi: "Kaikki viisi polkua yhdessä mahdollistavat yhteisen vision toteutumisen vuodelle 2028.",
    en: "All five paths together enable the realization of the common vision for 2028.",
  },
  visionsDescription4: {
    fi: "Oppilaitosten yhteinen visio on",
    en: "The joint vision of the educational institutions is",
  },
  visionQuote: {
    fi: "”Luomme puitteet arktisen teollisuuden ja teknologian sekä tulevaisuuden osaajien kasvulle.”",
    en: "\"We are creating a framework for the growth of Arctic industry and technology, as well as future talent.\"",
  },

  visio2028Title: {
    fi: "VISIO 2028",
    en: "VISION 2028",
  },
  visio2028CooperationTitle: {
    fi: "Yhteistyön kasvattaminen kokonaisvaltaisesti",
    en: "Increasing cooperation comprehensively",
  },
  visio2028Cooperation: {
    fi: "Pirstalemaisesta ja vaikeasti hahmotettavasta kokonaisuudesta siirrytään jatkuvan arvioinnin ja kehittämisen, hankkeiden ja kehittämisympäristöjen hyödyntämisen kautta kohti toimintamalleja, joissa kaikkien osaaminen saadaan käyttöön. Osaavaan ja sitoutuneeseen henkilöstöön kannattaa panostaa tällä polulla.",
    en: "Through continuous evaluation and development, projects and the utilization of development environments, we move from a fragmented and difficult-to-understand entity towards operating models where everyone's expertise is put to use. It is worth investing in competent and committed personnel on this path.",
  },
  visio2028CircularEconomyTitle: {
    fi: "Kiertotalous ja vihreä siirtymä",
    en: "",
  },
  visio2028CircularEconomy: {
    fi: "Yksittäisistä opintokokonaisuuksista ja teoista siirrytään verkostojen, teknologioiden ja näkyväksi tekemisen kautta kohti kokonaisvaltaista vastuullisuusperiaatteiden mukaista toimintaa.",
    en: "Through networks, technologies and visibility, we move from individual study units and activities towards comprehensive activities in accordance with the principles of responsibility.",
  },
  visio2028RegionalityTitle: {
    fi: "Alueellisuus",
    en: "Regionality",
  },
  visio2028Regionality: {
    fi: "Pohjoinen ulottuvuus näkyy jo osana oppilaitosten strategioita ja opintosuunnitelmia. Jotta hyvästä tulee parempaa, on hyvä keskittyä luomaan yhteistä brändiä ja toimintamallia, joka pohjautuu alueellisten tarpeiden ja muutosten ymmärrykseen.",
    en: "The northern dimension can already be seen as part of educational institutions' strategies and study plans. In order for good to become better, it is good to focus on creating a common brand and operating model that is based on an understanding of regional needs and changes.",
  },
  visio2028EducationTitle: {
    fi: "Kehittyvä koulutus",
    en: "Developing education",
  },
  visio2028Education: {
    fi: "Nykyiset esteet yhteistyön tekemiselle oppilaitosten sisällä ja välillä on poistettava, jotta oppilaitokset voivat toimia saumattomasti yhteen esimerkiksi tiettyjen kokonaisuuksien tai teemojen osalta. Ardico tarjoaa oppilaitoksille yhteisen alustan tiedon jakamiseen ja viestintään.",
    en: "Current barriers to cooperation within and between educational institutions must be removed so that educational institutions can work together seamlessly, for example with regard to certain entities or themes. Ardico offers educational institutions a common platform for information sharing and communication.",
  },
  visio2028TechnologyTitle: {
    fi: "Teknologia ja innovaatiot",
    en: "Technology and innovations",
  },
  visio2028Technology: {
    fi: "Uudet teknologiat eivät toimi ilman ihmisiä, jotka niitä käyttävät. Oppilaitoksissa yhteisen työskentelyn mahdollistavat erilaiset kehittämisympäristöt, joihin satsaaminen on oleellista vision saavuttamiseksi.",
    en: "New technologies do not work without the people who use them. Working together in educational institutions is enabled by different development environments, in which investment is essential to achieve the vision.",
  },

  coperationTitle: {
    fi: "YHTEISTYÖN TOIMINTAMALLI",
    en: "COOPERATIVE OPERATING MODEL",
  },
  coperationDescription: {
    fi: "Yhteistyö pohjautuu ja saa voimaa jaetusta kärkiosaamisesta oppilaitosten välillä. Osaaminen ohjautuu eri toiminta-alueille ja toiminta puolestaan kasvattaa osaamista edelleen.",
    en: "The cooperation is based on and gets strength from the shared top expertise between educational institutions. The know-how is directed to different operational areas, and the operation, in turn, further increases the know-how.",
  },
  coperationListTitle: {
    fi: "Yhteistyön toimintamallikoneiston tärkeät osakokonaisuudet ovat:",
    en: "The important sub-assemblies of the cooperation operating model mechanism are:",
  },
  coperationList: {
    fi: [
      "Kärkiosaaminen",
      "Opetus",
      "Oppilaitokset",
      "Kehittämisympäristöt",
      "Hankeyhteistyöt",
      "Yritysyhteistyöt"
    ],
    en: [
      "Top expertise",
      "Education",
      "Academy",
      "Development environments",
      "Project collaborations",
      "Business collaborations"
    ]
  },
  coperationContinue: {
    fi: "Jokainen osakokonaisuus on avattu tarkemmin alla.",
    en: "Each sub-assembly is opened in more detail below.",
  },

  coperationArrowTitle: {
    fi: "YHTEISTYÖN TOIMINTAMALLI: Kärkiosaaminen",
    en: "OPERATING MODEL OF COOPERATION: Top expertise",
  },
  coperationArrowChapter1: {
    fi: "Tunnistettu ja sanoitettu kärkiosaaminen auttaa rakentamaan yhteistyötä ja toimintamalleja yli organisaatio- ja alarajojen.",
    en: "Identified and verbalized top expertise helps to build cooperation and operating models across organizational and subordinate boundaries.",
  },
  coperationArrowChapter2: {
    fi: "Kärkiosaamisten ryhmittelyllä voidaan tunnistaa uusia osaamisaloja ja yhteistyön mahdollisuuksia.",
    en: "By grouping top skills, new fields of expertise and opportunities for cooperation can be identified.",
  },
  coperationArrowChapter3: {
    fi: "Kärkiosaamisalueet voivat toimia myös temaattisina ryhmittelyinä kasvavalle yhteistyölle ammattilaisten ja opiskelijoiden kesken.",
    en: "Areas of expertise can also serve as thematic groupings for growing cooperation between professionals and students.",
  },

  coperationTeachingTitle: {
    fi: "YHTEISTYÖN TOIMINTAMALLI: Opetus",
    en: "OPERATING MODEL OF COOPERATION: Education",
  },
  coperationTeachingChapter1: {
    fi: "Opetuksen osalta on tärkeää kehittää teknologista osaamista henkilökunnalla ja sitä kautta myös opiskelijoilla.",
    en: "As far as teaching is concerned, it is important to develop the technological competence of the staff and thus also of the students.",
  },
  coperationTeachingChapter2: {
    fi: "Uusien yhteistyömallien kokeileminen voisi keskittyä esimerkiksi opinnäytetöihin, teemoihin tai kehittämisympäristöihin.",
    en: "Experimenting with new collaboration models could focus on, for example, theses, themes or development environments.",
  },
  coperationTeachingChapter3: {
    fi: "Yhteinen toive on, että tulevaisuudessa voitaisiin vierailla säännöllisesti toisissa oppilaitoksissa. Kehittämisympäristöjen, yhteistyön ja avoimuuden kannalta on tärkeää päästä kasvokkain kokeilemaan toimintaa ja jakamaan ajatuksia.",
    en: "The common wish is that in the future we could regularly visit other educational institutions. In terms of development environments, cooperation and openness, it is important to be able to try out activities face-to-face and share ideas.",
  },
  coperationTeachingChapter4: {
    fi: "Kehittämisympäristöjen tulisi myös olla konkreettinen osa opintoja. Näin teknologioiden oppimiseen ja opitun testaamiseen saadaan tukea. Erilaisten yhteistöiden kautta opiskelijoilla on myös mahdollisuus oppia muilta opiskelijoilta eri oppilaitoksista.",
    en: "Development environments should also be a concrete part of studies. In this way, learning technologies and testing what has been learned can be supported. Through various collaborations, students also have the opportunity to learn from other students from different educational institutions.",
  },

  coperationAcademeTitle: {
    fi: "YHTEISTYÖN TOIMINTAMALLI: Oppilaitokset",
    en: "OPERATING MODEL OF COOPERATION: Academy",
  },
  coperationAcademeChapter1: {
    fi: "Tämä koneen osa keskittyy erityisesti oppilaitoksiin työympäristöinä, joissa kaikki alat nähdään yhtä tärkeinä.",
    en: "This part of the machine focuses especially on educational institutions as work environments where all fields are seen as equally important.",
  },
  coperationAcademeChapter2: {
    fi: "Organisaatiorajoja tulee edelleen madaltaa ja kumppanuussopimuksia viedä konkretiaan.",
    en: "Organizational boundaries must continue to be lowered and partnership agreements must be made concrete.",
  },
  coperationAcademeChapter3: {
    fi: "Kaikkien yhteinen toive on, että järjestettäisiin temaattisia tapahtumia ja mahdollisuuksia tutustumiseen sekä tiedonvaihtoon. Tämä on oleellista uusien yhteistyökuvioiden muodostumisessa.",
    en: "Everyone's common wish is to organize thematic events and opportunities to get to know each other and exchange information. This is essential in the formation of new cooperation patterns.",
  },
  coperationAcademeChapter4: {
    fi: "Mahdollisuus kohdistaa työaikaa yhteistyöhön on oleellinen osa toimintamallin toimivuudelle. Resursseja ja osaamista tulisi myös jakaa entistä enemmän.",
    en: "The possibility of allocating working time to cooperation is an essential part of the operational model's functionality. Resources and know-how should also be shared even more.",
  },

  coperationInfrastuctureTitle: {
    fi: "YHTEISTYÖN TOIMINTAMALLI: Kehittämisympäristöt",
    en: "OPERATING MODEL OF COOPERATION: Development environments",
  },
  coperationInfrastuctureChapter1: {
    fi: "Kehittämisympäristöt kytkeytyvät vahvasti kärkiosaamiseen sekä yritysyhteistyöhön. Kehittämisympäristöt toimivat alustoina yhteistyölle ja erilaisille piloteille sekä kokeiluille. Kehitysympäristöillä on myös tärkeä rooli linkkinä ja tulkkina yritysten ja oppilaitosten välillä.",
    en: "Development environments are strongly linked to top expertise and business cooperation. Development environments serve as platforms for collaboration and various pilots and experiments. Development environments also play an important role as a link and interpreter between companies and educational institutions.",
  },
  coperationInfrastuctureChapter2: {
    fi: "Yhteisten toiminnan kannalta on tärkeää lisätä kehittämisympäristöjen avoimuutta ja kehittää jaetun resurssoinnin mahdollisuuksia.",
    en: "From the point of view of joint operations, it is important to increase the openness of development environments and to develop the possibilities of shared resourcing.",
  },
  coperationInfrastuctureChapter3: {
    fi: "Osaava henkilöstö kehitysympäristöjen toiminnan vastuussa mahdollistaa niiden vakaan toimivuuden.",
    en: "Competent personnel responsible for the operation of development environments enables their stable functionality.",
  },
  coperationInfrastuctureChapter4: {
    fi: "Ardico toimii tiedostusalustana kehittämisympäristöjen kokonaisuudelle. Kehittämisympäristöt esitetään yhtenä kokonaisuutena ja niiden toiminnasta kerättyä dataa jaetaan myös eteenpäin.",
    en: "Ardico serves as an information platform for the entirety of development environments. The development environments are presented as a single entity and the data collected from their operation is also shared.",
  },
  coperationInfrastuctureChapter5: {
    fi: "Kehittämisympäristöjen avaaminen sekä oppilaiden, henkilöstön, yritysten että tutkimuksen käyttöön mahdollistaa tilojen tehokkaamman hyödyntämisen.",
    en: "Opening the development environments to the use of both students, personnel, companies and research enables a more efficient use of the facilities.",
  },

  coperationProjectsTitle: {
    fi: "YHTEISTYÖN TOIMINTAMALLI: Hankeyhteistyöt",
    en: "OPERATING MODEL OF COOPERATION: Project collaborations",
  },
  coperationProjectsChapter1: {
    fi: "Kaikissa oppilaitoksissa on paljon hankkeita ja hanketyöntekijöitä, jotka ovat upea resurssi kehittymiseen sekä yhteistyöhön.",
    en: "All educational institutions have many projects and project workers, which are a wonderful resource for development and cooperation.",
  },
  coperationProjectsChapter2: {
    fi: "Yhteinen toive on, että keskinäinen kilpailu oppilaitosten välillä voitaisiin poistaa (ainakin suurilta osin). Näin saataisiin hanketoiminnasta tehokkaampaa ja resursseja käytettyä järkevämmin yhteisiin tavoitteisiin.",
    en: "The common wish is that mutual competition between educational institutions could be eliminated (at least to a large extent). In this way, project activities would be more efficient and resources would be used more sensibly for common goals.",
  },
  coperationProjectsChapter3: {
    fi: "Yhteistyötä ja läpinäkyvyyttä on tarpeen lisätä myös hankesuunnittelussa, jotta tämä toteutuu.",
    en: "It is also necessary to increase cooperation and transparency in project planning in order to make this happen.",
  },
  coperationProjectsChapter4: {
    fi: "Kaikkien oppilaitosten yhteisiä hankkeita, kuten ASFT on ollut, kaivataan lisää.",
    en: "More joint projects of all educational institutions, such as ASFT has been, are needed.",
  },
  coperationProjectsChapter5: {
    fi: "Tarvitaan myös koonteja, tulosten jakamista ja sovittuja sääntöjä, jotta hanketoiminta kehittyy yhteistyössä eteenpäin.",
    en: "Teams, sharing of results and agreed rules are also needed in order for the project to develop forward in cooperation.",
  },

  coperationCompaniesTitle: {
    fi: "YHTEISTYÖN TOIMINTAMALLI: Yritysyhteistyöt",
    en: "OPERATING MODEL OF COOPERATION: Business collaborations",
  },
  coperationCompaniesChapter1: {
    fi: "Yritysyhteistyön avain on vuoropuhelu, jota tulee tukea opastuksella ja neuvonnalla. Opastuksen kautta voidaan avata yhteistyön mahdollisuuksia ja antaa tukea toimeksiantojen muodostamiseen.",
    en: "The key to business cooperation is dialogue, which should be supported by guidance and advice. Through guidance, opportunities for cooperation can be opened up and support for creating assignments can be given.",
  },
  coperationCompaniesChapter2: {
    fi: "Palvelut ja yhteistyömahdollisuudet tulisi kuvata mahdollisimman selkeästi ja tarkasti, jotta niihin olisi helpompaa tarttua. Konkreettiset esimerkit auttavat ymmärtämään yhteistyön muotoa ja mahdollisia tuloksia. Tavoitteena on tunnistaa erilaiset yhteistyömallit erilaisille yrittäjille ja tehdä työtä sen eteen, että oppilaitokset nähdään mahdollisina kehittämiskumppaneina.",
    en: "Services and cooperation opportunities should be described as clearly and precisely as possible, so that it would be easier to grasp them. Concrete examples help to understand the form of cooperation and possible results. The goal is to identify different cooperation models for different entrepreneurs and work to ensure that educational institutions are seen as potential development partners.",
  },
  coperationCompaniesChapter3: {
    fi: "Yritysyhteistöiden kautta pyritään kehittämään uusia innovaatioita ja löytämään mahdollisuuksia niiden soveltamiseen yritysten liiketoiminnassa.",
    en: "Through business partnerships, the aim is to develop new innovations and find opportunities for their application in the business of companies.",
  },
  coperationCompaniesChapter4: {
    fi: "Oppilaitokset yhdessä haluavat tukea alueen start-up -toimintaa auttamalla uusien start-upien muodostamisessa sekä olemassa olevien toiminnassa.",
    en: "Together, the educational institutions want to support the region's start-up activities by helping with the formation of new start-ups and the operation of existing ones.",
  },
};
